// SideDrawer.js
import React from 'react';
import './join.css'; // Import your CSS for styling

function Join() {
    const handleBuy = () => {
        const externalUrl = 'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x0775B2429A23e24D3f206FC01986D3b68a3E9096';
        window.open(externalUrl, '_blank');
      };

    return (
        <div className="main-join-div">
            <div className="heading">
                <p>Reach Out, Join the Pink Hippo Party!</p>
            </div>
            {/* <div className="sub-heading-token">
                <p>Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Forem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div> */}
            <div className="banner-download-div" style={{justifyContent:"center"}}>
                            <button onClick={handleBuy} style={{maxWidth:"300px"}}>Join Us</button>
                            <img onClick={handleBuy} className="download-img" src="/images/buyNow.svg" alt="buyNow" />
                        </div>

        </div>
    );
}

export default Join;
