import React from "react";
import './about.css';
import Grid from '@mui/material/Grid';
const viewportWidth = window.innerWidth;
function About() {

    console.log(viewportWidth, "viewport")
    return (
        <div className="main-about-div">
                 <div className="heading about-heading">
                <div className="horizontal-divider"></div> <p>About Us</p> <div className="horizontal-divider"></div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <img className="about-img" src="images/about-img.png" alt="about-img" />
                </Grid>
                <Grid item md={1} className="about-divider-div">
                    <img className="about-divider-img" src="images/about-divider.png" alt="about-divider" />
                </Grid>


                <Grid item xs={12} md={7}>
                    <p className="about-content">
                    At Pink Hippo, we're more than just an app; we're your Global Party Passport, your ticket to explore the vibrant world of celebration. Our journey began with a bold vision: to unite party enthusiasts from every corner of the globe and offer them a seamless way to discover and experience diverse events and parties worldwide.
                    </p>

                    <p className="about-content"> We envision a world where celebration knows no boundaries—a world where the rhythm of life is set to the beat of celebration. Pink Hippo is our answer to this vision, a platform that connects you with iconic clubs, legendary events, and high-energy parties across continents. </p>


                </Grid>
            </Grid>
        </div>
    );
}

export default About;
