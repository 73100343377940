// SideDrawer.js
import React from 'react';
import './sideBar.css'; // Import your CSS for styling
import CloseIcon from '@mui/icons-material/Close';
import { Link, Element } from 'react-scroll';
import Tooltip from '@mui/material/Tooltip';
const data = [
  {name:"Home",id:"Home"}, {name:"About Us",id:"About-Us"}, {name:"How to Buy",id:"How-to-Buy"}, {name:"Tokenomics",id:"Tokenomic"}, {name:"Road Map",id:"Roadmap"},{name:"FAQ",id:"faq"}
]
function SideDrawer(props) {
  // Use props to determine whether the side drawer is open or closed
  const { isOpen, onClose } = props;

  return (
    <div className={`side-drawer ${isOpen ? 'open' : ''}`}>
      <nav>
        <div className='close-div' onClick={onClose}><CloseIcon /></div>
        <ul>
        {data.map((data)=>{
                        return(
                          <li><Link to={data.id} smooth={true} duration={500} onClick={onClose}>{data.name}</Link></li>
                        )
                       
                    })}
                     <li style={{ cursor: "default" }}><Tooltip title="Coming soon">NFT</Tooltip></li>
        </ul>
      </nav>
    </div>
  );
}

export default SideDrawer;
