import React from "react";
import './dex.css';
import Grid from '@mui/material/Grid';

const data = [
    {
        name:"Pancakeswap",
        href:"/",
        img:"images/pancakeswap.svg"
    },
    {
        name:" Gate.io",
        href:"/",
        img:"images/gate.svg"
    },
    {
        name:"Huobi",
        href:"/",
        img:"images/huobi.svg"
    },
    {
        name:"Poloniex",
        href:"/",
        img:"images/poloniex.svg"
    },
    {
        name:"Bingx",
        href:"/",
        img:"images/binx.svg"
    },
    {
        name:"Trustwallet",
        href:"/",
        img:"images/trustwallet.svg"
    },
    {
        name:"Digifinex",
        href:"/",
        img:"images/digifinex.svg"
    },
    {
        name:"Btcex",
        href:"/",
        img:"images/btcex.svg"
    },
    {
        name:"Lbank",
        href:"/",
        img:"images/lbank.svg"
    },
    {
        name:"Dextools",
        href:"/",
        img:"images/dextools.svg"
    },
    {
        name:"Coinmarketcap",
        href:"/",
        img:"images/coinmarketcap.svg"
    },
    {
        name:"Coingecko",
        href:"/",
        img:"images/coingecko.svg"
    },


]



function Dex() {
    return (
   <div className="main-dex-div">
    {data.map((data)=>
        <div className="dex-button">
      
            <img src={data.img} alt={data.name} />
             <p>{data.name}</p>
    </div>
    )}

   </div>
    );
}

export default Dex;
