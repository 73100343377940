// Header.js
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import "./header.css";
import SideDrawer from './sidebar';
import { Link } from 'react-scroll';
import Tooltip from '@mui/material/Tooltip';
const data = [
    { name: "Home", id: "Home" }, { name: "About Us", id: "About-Us" }, { name: "How to Buy", id: "How-to-Buy" }, { name: "Tokenomics", id: "Tokenomic" }, { name: "Road Map", id: "Roadmap" }, { name: "FAQ", id: "faq" }
]
function Header() {
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

    const toggleSideDrawer = () => {
        setIsSideDrawerOpen(!isSideDrawerOpen);
    };
    const handleBuy = () => {
        const externalUrl = 'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x0775B2429A23e24D3f206FC01986D3b68a3E9096';
        window.open(externalUrl, '_blank');
      };
    return (
        <header className="header">
            <div className="logo">
                <img src="images/logo.png" alt="Logo" />
            </div>
            <nav className="nav">
                <ul>

                    {data.map((data) => {
                        return (
                            <li style={{ cursor: "pointer" }}><Link to={data.id} smooth={true} duration={500}>{data.name}</Link></li>
                        )

                    })}
                    <li style={{ cursor: "default" }}><Tooltip title="Coming soon">NFT</Tooltip></li>


                </ul>
            </nav>
            <div className="buy-button">
                <button onClick={handleBuy} className='buy-now'>Buy Now</button>

                <div className="hamburger-css"><MenuIcon onClick={toggleSideDrawer} /></div>
            </div>
            <SideDrawer isOpen={isSideDrawerOpen} onClose={toggleSideDrawer} />
        </header>
    );
}

export default Header;
