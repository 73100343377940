import React from "react";
import './banner.css';
import Grid from '@mui/material/Grid';
function Banner() {
    const handleLinkClick = () => {
        const externalUrl = 'https://whitepaper.pinkhippo.world/';
        window.open(externalUrl, '_blank');
      };
      const handleAudit = () => {
        const externalUrl = 'https://github.com/cyberscope-io/audits/blob/main/ph/audit.pdf';
        window.open(externalUrl, '_blank');
      };
      const hanleContract = () => {
        const externalUrl = 'https://bscscan.com/tx/0x20ffe6d843aef45def288dc0ce13210af496af4cf853797df791dd74883a59ef';
        window.open(externalUrl, '_blank');
      };
      const kyc = () => {
        const externalUrl = 'https://assuredefi.com/projects/pink-hippo/';
        window.open(externalUrl, '_blank');
      };

    return (
        <div className="main-banner-box">
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                    <div className="left-side-banner">
                        <p className="heading">Welcome to Pink Hippo – Your Global Party Passport! </p>
                        <p className="sub-heading">At Pink Hippo, our mission is to unite the party world by connecting partygoers with the hottest clubs and events across the globe. We're here to make every night out an unforgettable experience.</p>
                        <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <div className="banner-download-div">
                            <button onClick={hanleContract}>Contract ownership is  renounce</button>
                            {/* <img onClick={handleLinkClick} className="download-img" src="/images/buyNow.svg" alt="download" /> */}
                        </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <div className="banner-download-div">
                            <button onClick={handleLinkClick}>Whitepaper</button>
                            <img onClick={handleLinkClick} className="download-img" src="/images/buyNow.svg" alt="download" />
                        </div>
                            </Grid>
                   
                            <Grid item xs={12} sm={6}>
                            <div className="banner-download-div">
                            <button onClick={kyc}>KYC</button>
                            <img onClick={kyc}  className="download-img" src="/images/buyNow.svg" alt="download" />
                        </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <div className="banner-download-div">
                            <button onClick={handleAudit}>Audit</button>
                            <img onClick={handleAudit} className="download-img" src="/images/buyNow.svg" alt="download" />
                        </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <div className="banner-download-div">
                            <button >0% Tax</button>
                            <img  className="download-img" src="/images/buyNow.svg" alt="download" />
                        </div>
                            </Grid>
                          
                        </Grid>
                    </div>
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <img className="hippo-banner" src="images/logo.png" alt="hippo" />
                </Grid>
            </Grid>
        </div>
    );
}

export default Banner;
