import React from "react";
import './roadmap.css';
import Grid from '@mui/material/Grid';
const phaseOne = {
    name: "Phase 1",
    content: "Creation of a PINKHIPPO ideology website creation and launch PINKHIPPO release Marketing capaign Consulting and advisor Army of PINKHIPPO Betting platform Beta Release",
    img: ""
}
const phaseTwo = {
    name: "Phase 2",
    content: "Huge Marketing Campaign DEX Launch CoinGecko and CoinMarketCap Campaign 2000 holders Partnerships with influencers Billboard Advertisement in front of SpaceX office Betting Platform Testnet Lounch",
    img: ""
}
const phaseThree = {
    name: "Phase 3",
    content: "Available Betting and Gambling Platform Merchandising for community Tier 2 CEX Listings Working on P2E game 10 Million MarketCap Ads campaign with Crypto Ambassadors NFT Marketplace release",
    img: ""
}
function Step() {
    return (
        <div>
            <div className="heading">
                <div className="horizontal-divider"></div> <p>Roadmap</p> <div className="horizontal-divider"></div>
            </div>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <div className="name-content-div">
                        <p className="name">{"Phase 1"}</p>
                        <ul>
                            <li className="content">Creation of a PINKHIPPO ideology.</li>
                            <li className="content">Creating and deploying contract.</li>
                            <li className="content">Website creation and launch.</li>

                            <li className="content"> Establishing Pink Hippo accounts on social media.</li>
                            <li className="content">Whitepaper release.</li>
                            <li className="content">Huge Marketing campaign.</li>
                            <li className="content">Holding presale on pinksale.</li>
                            <li className="content">locking liquidity for 6 months initially.</li>
                        </ul>

                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    {/* <div className="img-div"></div> */}
                    <img className="img-div" src="images/phase1.jpg" alt="phase1" />
                </Grid>
            </Grid>
            <br />
            <br />

            <img style={{ width: "100%" }} src='images/dashDivider.png' alt="dash-divider" />
            <br />
            <br />


            <Grid container spacing={2}>

                <Grid item xs={12} md={7}>
                    <div className="name-content-div">
                        <p className="name">{"Phase 2"}</p>
                        <ul>
                            <li className="content">CoinGecko and CoinMarketCap Campaign</li>
                            <li className="content">2000 holders</li>
                            <li className="content">Partnership in Pink Hippo</li>
                            <li className="content">Early Access Registration</li>
                            <li className="content">Pink Hippo Army.</li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img className="img-div" src="images/phase2.jpg" alt="phase2" />
                </Grid>
            </Grid>
            <br />
            <br />

            <img style={{ width: "100%" }} src='images/dashDivider.png' alt="dash-divider" />
            <br />
            <br />


            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <div className="name-content-div">
                        <p className="name">{"Phase 3"}</p>
                        <ul>
                            <li className="content">Tier 2 Listings.</li>
                            <li className="content">10 Million MarketCap.</li>
                            <li className="content">Ads campaign with Crypto Ambassadors.</li>
                            <li className="content">NFT Marketplace release.</li>
                            <li className="content">Huge Marketing.</li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img className="img-div" src="images/phase3.jpg" alt="phase3" />
                </Grid>

            </Grid>

            <br />
            <br />

            <img style={{ width: "100%" }} src='images/dashDivider.png' alt="dash-divider" />
            <br />
            <br />


            <Grid container spacing={2}>

                <Grid item xs={12} md={7}>
                    <div className="name-content-div">
                        <p className="name">{"Phase 4"}</p>
                        <ul>
                            <li className="content">Final Event Organization Platform release.</li>
                            <li className="content">Merchendising for community.</li>
                            <li className="content">Party starts for the lifetime.</li>
                            <li className="content">2,00,000 holders.</li>
                            <li className="content">50 Million MarketCap.</li>
                            <li className="content">Listing on Tier 1 exchanges.</li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img className="img-div" src="images/phase4.jpg" alt="phase4" />
                </Grid>


            </Grid>
        </div>

    );
}

export default Step;
