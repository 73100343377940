import React, {useState} from "react";
import './tokenomics.css';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
function Step() {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = async () => {
      try {
        // Copy text to the clipboard using the Clipboard API
        await navigator.clipboard.writeText("0x0775b2429a23e24d3f206fc01986d3b68a3e9096");
        setIsCopied(true);
      } catch (error) {
        console.error('Error copying text: ', error);
      }
      setTimeout(()=>{
        setIsCopied(false);
      },2000)
    };
    const handleBuy = () => {
        const externalUrl = 'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x0775B2429A23e24D3f206FC01986D3b68a3E9096';
        window.open(externalUrl, '_blank');
      };
    return (
        <div>
            <div className="heading">
                <div className="horizontal-divider"></div> <p>Tokenomics</p> <div className="horizontal-divider"></div>
            </div>
            {/* <div className="sub-heading-token">
                <p>PINKHIPPO is a native currency for betting platforms, providing users with a straightforward payment option. With 93.1% of tokens in the liquidity pool and LP tokens burnt, PINKHIPPO ensures stability.</p>
            </div> */}

            <div>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        {/* <div className="token-supply-div">
                <p className="title">Token Supply:</p>
                <p className="number">10,000,000,000</p>
            </div> */}
  
                        <div >

                            <ul>
                            <li className="content">Total Supply: 10000000000 PH</li>
                                <li className="content">Token allocation for CEX listning: 15% </li>
                              

                                {/* <li className="content">Team and Advisors: 15% (vested over 3 years)</li> */}
                                <li className="content">Partnerships and Collaborations: 10%</li>
                                <li className="content">Reserve Fund: 10%</li>
                                <li className="content">Community Rewards and Airdrops: 5%</li>
                                <li className="content">Public Sale: 60%</li>
                            </ul>
                        </div>
                        <div className="token-supply-div address-div">
                <p className="title">Contract Address:</p>
                <div className="combine-of-number-copy">
                <p className="address">0x0...s9096</p>
                <div className="copy-div"><ContentCopyIcon style={isCopied ? {color:"green", cursor:"pointer"} : {color:"white", cursor:"pointer"}} onClick={handleCopyClick}/></div>
                </div>
            </div>
                        <div className="tokenomic-download-div" style={{ justifyContent: "center" }}>
                            <button onClick={handleBuy}>Buy Now</button>
                            <img onClick={handleBuy} className="download-img" src="/images/buyNow.svg" alt="buyNow" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div className="token-coin-div">
                            <img className="token-coin" src='images/token.png' alt="token" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Step;
