import React from "react";
import './footer.css';
import { Grid } from "@mui/material";
import { Link } from 'react-scroll';

function Footer() {
    const handleClick = (type) => {
        if (type == "facebook") {
            window.open('https://www.facebook.com/pinkhippoworld', '_blank');
        }
        else if (type == "instagram") {
            window.open('https://www.instagram.com/pinkhippoworld/', '_blank');
        }
        else {
            window.open("https://twitter.com/pinkhippoworld", '_blank');
        }
    };
    const handleBuy = () => {
        const externalUrl = 'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x0775B2429A23e24D3f206FC01986D3b68a3E9096';
        window.open(externalUrl, '_blank');
      };
      const openSystemEmailClient = () => {
        window.location.href = 'mailto:';
      };
    return (
        <div className="main-footer-div">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} >
                    <div className="logo-div">

                        <img src="images/logo.png" alt="Logo" className="logo-footer"/>
                        <div className="banner-download-div" style={{ justifyContent: "initial" }}>
                            <button onClick={handleBuy} style={{maxWidth:"300px"}}>Join Us</button>
                            <img onClick={handleBuy} className="download-img" src="/images/buyNow.svg" alt="buyNow" />
                        </div>
                    </div></Grid>


                {/* ------------------------------ */}
                {/* <Grid item xs={12} sm={6} md={4}>
                    <div className="nav-link-div">
                        <ul>
                            <li>Terms & Conditions</li>
                            <li>Privacy Policy</li>
                            <li>Whitepaper</li>
                            <li><Link to={"Tokenomic"} smooth={true} duration={500}>Tokenomics</Link></li>
                            <li><Link to={"faq"} smooth={true} duration={500}>FAQ's</Link></li>
                        </ul>
                    </div>
                </Grid> */}

                {/* ------------------------------ */}
                <Grid item xs={12}  sm={6}>
                    <div className="contact-div">
                        <div className="find-us">
                            <p>Find Us</p>
                            <div className="social-link-div">                        <img onClick={()=>handleClick("instagram")} src='images/instagram.svg' alt='instagram'></img>
                                <img onClick={()=>handleClick("facebook")} src='images/facebook.svg' alt='facebook'></img>
                                <img onClick={()=>handleClick("twitter")} src='images/twitter.svg' alt='twitter'></img>
                                {/* <img src='images/linkdin.svg' alt='linkdin'></img></div> */}

                        </div>
                        <br />
                        <div className="contact-us-div">
                            <p className="contact-us">Contact Us</p>
                            <div>
                                <p onClick={openSystemEmailClient} className="address" style={{pointer:"pointer"}}>
                                    {"Email: support@pinkhippo.world"}
                                </p>
                                {/* <p className="address">{"Phone: +1-123-456-7890"}</p> */}
                                <p className="address">{"Address: 123 Party Lane, Cityville, Partyland"}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </Grid>




            </Grid>

            <br />
            <br />
            <p className="footer-text">© 2023 by PINKHIPPO. All rights reserved!</p>
        </div>
    );
}

export default Footer;
