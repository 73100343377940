import { useEffect, useState } from 'react';
import './App.css';
import Banner from './New/banner/banner'
import NewHeader from './New/header/NewHeader';
import Dex from "./New/dex/dex";
import About from './New/about/about';
import Step from "./New/stepToBuy/step";
import Tokenomics from './New/tokenomics/tokenomics';
import RoadMap from "./New/roadmap/roadmap"
import FAQ from "./New/faq/faq";
import Footer from './New/footer/footer';
import Join from './New/join/join'
import { Link, Element } from 'react-scroll';
function App() {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto"

    return () => {

    }
  }, [isOpen])

  return (
   
    <div className={"App" + (isOpen ? " overlay-open" : "")}>
      <NewHeader isOpen={isOpen} setOpen={setOpen} />
        <Element name="Home">
        <div className='main-background global-for-all-div' id="Home">
        
        <Banner />
      </div>
      </Element>
 
      {/* Dex section */}
      <div className='dex-section-div global-for-all-div'>
        <Dex />
      </div>
      {/* About section */}
      <Element name="About-Us">
      <div className='about-section-div global-for-all-div' id="About-Us">
        <About />
      </div>
      </Element>
      {/* Step to buy section */}
      <Element name="How-to-Buy">
      <div className='step-section-div global-for-all-div' id="How-to-Buy">
        <Step />
      </div>
      </Element>
      {/* tokenomics section */}
      <Element name="Tokenomic">
      <div className='tokenomics-section-div global-for-all-div' id="Tokenomic">
        <Tokenomics />
      </div>
      </Element>
      {/* roadmap section */}
      <Element name="Roadmap">
      <div className='roadmap-section-div global-for-all-div ' id="Roadmap">
        <RoadMap />
      </div>
      </Element>
      {/* faq section */}
      <Element name="faq">
           <div className='about-section-div global-for-all-div '>
        <FAQ />
      </div>
      </Element>
            {/* Join Us section */}
            <div className='tokenomics-section-div global-for-all-div '>
        <Join />
      </div>
      {/* footer section */}
      <div className=' footer-css'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
