import React, { useState, useEffect } from "react";
import './faq.css';
import Grid from '@mui/material/Grid';



function About() {
    // State to keep track of which FAQ items are expanded
    const [expandedItems, setExpandedItems] = useState([]);

    // Function to toggle the expansion of an FAQ item
    const toggleItem = (index) => {
        if (expandedItems.includes(index)) {
            setExpandedItems(expandedItems.filter((item) => item !== index));
        } else {
            setExpandedItems([...expandedItems, index]);
        }
    };
    const [faqData, setFaqData] = useState([]);
    useEffect(() => {

        fetch('static/faq.json')
            .then((response) => response.json())
            .then((data) => {
                setFaqData(data);
                console.log(data, "data")
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    return (
        <div className="main-about-div">

            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <div className="heading about-heading">
                        <div className="horizontal-divider"></div> <p>FAQ’s</p> <div className="horizontal-divider"></div>
                    </div>
                    <img className="faq-image" style={{ width: "100%" }} src="images/faq.png" alt="faq" />
                </Grid>
                <Grid item xs={12} md={7} className="faq-box" style={{
                    height: "490px",
                    overflow: "auto"
                }}>
                    {faqData.map((item, index) => (

                        <div key={index}>

                            <div className="question-faq-div">
                                <p className="question-faq">{item.ques}</p>
                                {expandedItems.includes(index) ? <img cla src="images/close.svg" alt="action" onClick={() => toggleItem(index)} /> : <img src="images/open.svg" alt="action" onClick={() => toggleItem(index)} />}

                            </div>
                            {expandedItems.includes(index) && <p className="answer-faq">{item.ans}</p>}
                            <br />
                            <br />
                        </div>

                    ))}
                </Grid>





            </Grid>
        </div>
    );
}

export default About;
