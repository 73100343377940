import React from "react";
import './step.css';
import Grid from '@mui/material/Grid';
const data = [
    {
        name: "Create a Wallet",
        img: "images/wallet.svg",
        content: "Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension.",
        width:"100px"
    },
    {
        name: "Get Some BNB",
        img: "images/bnb.svg",
        width:"100px",
        content: "Have BNB in your wallet to switch to $PINKHIPPO. If you don’t have any BNB, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet."
    },
    {
        name: "Go to Pancakeswap",
        img: "images/pancake.svg",
        width:"100px",
        content: "Connect to Pancakeswap. Go to Pancakeswap.finance in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $PINKHIPPO token address into Pancakeswap, select PINKHIPPO, and confirm. "
    },
    {
        name: "Switch to PINKHIPPO",
        img: "images/switch.svg",
        width:"195px",
        content: "Switch BNB for $PINKHIPPO. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility."
    },

]
function Step() {
    const handleBuy = () => {
        const externalUrl = 'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x0775B2429A23e24D3f206FC01986D3b68a3E9096';
        window.open(externalUrl, '_blank');
      };
    return (
        <div>
            <div className="heading">
                <div className="horizontal-divider"></div> <p>Steps to Buy PINKHIPPO</p> <div className="horizontal-divider"></div>
            </div>
            {/* <div className="stepper-div">
            <div className="stepper"><p>1</p></div>
            <div className="dash-divider"></div>
            <div className="stepper"><p>2</p></div>
            <div className="dash-divider"></div>
            <div className="stepper"><p>3</p></div>
            <div className="dash-divider"></div>
            <div className="stepper"><p>4</p></div>
            </div> */}
<div style={{marginBottom:60}}>
            <Grid container spacing={2}>
                {data.map((item, index) => ( // Use parentheses to return JSX
                    <Grid key={index} item xs={12} sm={6} md={3}>
                        <div className="content-card">
                            <p className="content-name">{item.name}</p>
                            <img className="content-image" style={{width:"100%",maxWidth:item.width}} src={item.img} alt={item.name} />
                            <p className="content-content">{item.content}</p>
                        </div>
                    </Grid>
                ))}
            </Grid>
            </div>
            <div className="banner-download-div" style={{justifyContent:"center"}}>
                            <button onClick={handleBuy} style={{maxWidth:"300px"}}>Buy Now</button>
                            <img onClick={handleBuy} className="download-img" src="/images/buyNow.svg" alt="buyNow" />
                        </div>
        </div>
    );
}

export default Step;
